export const colorsNatureActe = {
  Soins: "#F7D88C",
  Prothèses: "#A6D3F2",
  Othro: "#FF6957",
  Implant: "#AFC4A8",
  Radio: "#FBD4FE",
  Paro: "#F1AA9B",
  Extraction: "#A090CB",
  Pedodontie: "#FDF1DA",
  Consultation: "#FBBFCA",
  Ouverture: "#DBF4A7",
};
