import React, { useState, useEffect } from "react";

import {
  getPathologies,
  deletePathologie,
} from "../../../services/pathologieService";

import PathologieForm from "./pathologieForm";
import PathologiesTable from "./pathologiesTable";

import SearchBox from "../../../common/searchBox";

import _ from "lodash";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";

function Pathologies(props) {
  const [itemOffset, setItemOffset] = useState(0);
  const [pathologies, setPathologies] = useState([]);
  const [filteredPathologies, setFilteredPathologies] = useState([]);
  const [selectedPathologie, setSelectedPathologie] = useState(null);
  const [selectedPathologies, setSelectedPathologies] = useState([]);

  const [filterDisplay, setFilterDisplay] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(true);
  const [displayForm, setDisplayForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState({ path: "nom", order: "desc" });
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const pageSize = 10;
  useEffect(() => {
    const fetchData = async () => {
      const { data: pathologies } = await getPathologies();
      setPathologies(pathologies);
    };
    if (dataUpdated) fetchData();
    setDataUpdated(false);
  }, [dataUpdated]);

  useEffect(() => {
    let filtered = pathologies;
    const getData = async () => {
      if (searchQuery)
        filtered = pathologies.filter((m) =>
          m.nom.toLowerCase().startsWith(searchQuery.toLowerCase()),
        );
      const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

      const endOffset = itemOffset + pageSize;
      setFilteredPathologies(sorted.slice(itemOffset, endOffset));
    };
    if (pathologies.length !== 0) getData();
    setTotalCount(filtered.length);
  }, [
    currentPage,
    pathologies,
    searchQuery,
    itemOffset,
    sortColumn.order,
    sortColumn.path,
  ]);

  const handleDelete = async (items) => {
    const originalPathologies = pathologies;
    setPathologies(
      pathologies.filter((c) => {
        let founded = items.find((p) => p._id.toString() === c._id.toString());
        if (founded) return false;
        return true;
      }),
    );
    setSelectedPathologie(null);
    setSelectedPathologies([]);
    try {
      items.forEach(async (item) => {
        await deletePathologie(item._id);
      });
      toast.success("pathologie supprimé");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("pathologie déja supprimé");
      setPathologies(originalPathologies);
    }
  };
  const handleSelectPathologie = (pathologie) => {
    let newSelectedPathologies = [...selectedPathologies];

    const index = newSelectedPathologies.findIndex(
      (c) => c._id.toString() === pathologie._id.toString(),
    );
    if (index === -1) newSelectedPathologies.push(pathologie);
    else newSelectedPathologies.splice(index, 1);
    let selectedPathologie = null;
    let founded = pathologies.find(
      (p) => p._id.toString() === pathologie._id.toString(),
    );
    if (founded && newSelectedPathologies.length === 1)
      selectedPathologie = founded;
    setSelectedPathologies(newSelectedPathologies);
    setSelectedPathologie(
      newSelectedPathologies.length === 1 ? selectedPathologie : null,
    );
    setDisplayForm(false);
  };
  const handleSelectPathologies = () => {
    let newSelectedPathologies =
      selectedPathologies.length === filteredPathologies.length
        ? []
        : [...filteredPathologies];
    setSelectedPathologies(newSelectedPathologies);
    setSelectedPathologie(
      newSelectedPathologies.length === 1 ? newSelectedPathologies[0] : null,
    );
  };
  const handlePageClick = (event) => {
    const newOffset = (event.selected * pageSize) % totalCount;
    setItemOffset(newOffset);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  const toggleFilter = () => {
    setFilterDisplay(!filterDisplay);
  };
  const updateData = () => {
    setDataUpdated(true);
    setSelectedPathologie(null);
    setSelectedPathologies([]);
    setDisplayForm(false);
  };
  const handleEdit = () => {
    setDisplayForm(true);
  };
  const toggleForm = () => {
    setSelectedPathologie(null);
    setSelectedPathologies([]);
    setDisplayForm(!displayForm);
  };
  if (totalCount === 0) {
    return (
      <>
        <button
          className="no-underlin mr-2 mt-2 flex h-6 min-w-fit cursor-pointer list-none rounded-lg bg-[#4F6874] pl-2 pr-2 pt-1 text-center text-xs font-bold text-white"
          onClick={toggleForm}
        >
          + Nouvelle pathologie
        </button>
        <PathologieForm
          selectedPathologie={selectedPathologie}
          formToggle={toggleForm}
          updateData={updateData}
          formDisplay={displayForm}
        />
        {!filterDisplay ? (
          <button
            onClick={toggleFilter}
            className="mr-2 mt-2 flex h-6 min-w-fit cursor-pointer list-none rounded-lg bg-[#4F6874] pl-2 pr-2 pt-1 text-center text-xs font-bold text-white"
          >
            <svg className="mr-2" width="15" height="15" fill="none">
              <rect width="15" height="15" rx="3" fill="#ffffff" />
              <path
                d="M3 9V7H6.5V3.5H8.5V7H12.5V9H8.5V13H6.5V9H3Z"
                fill="#4F6874"
              />
            </svg>
            Critère de recherche
          </button>
        ) : (
          <div className="w-full min-w-fit  rounded-md        bg-white  pb-2 shadow-component  ">
            <button
              onClick={toggleFilter}
              className=" mr-2 mt-2 flex h-6 min-w-fit cursor-pointer list-none rounded-lg bg-[#4F6874] pl-2 pr-2 pt-1 text-center text-xs font-bold text-white"
            >
              <svg className="mr-2" width="15" height="15" viewBox="0 0 15 15">
                <rect width="15" height="15" rx="3" fill="#ffffff" />
                <path d="M3 9V7H12.5V9H3Z" fill="#4F6874" />
              </svg>
              Critère de recherche
            </button>

            <SearchBox
              value={searchQuery}
              onChange={handleSearch}
              label={"nom du pathologie"}
            />
          </div>
        )}
        <div className="mt-1 flex h-fit w-full min-w-fit flex-col rounded-5px border border-white bg-white shadow-component ">
          <p className="m-2 mt-2 w-[100%] text-xl font-bold text-[#474a52]">
            Aucune pathologie trouvée
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <button
        className="no-underlin mr-2 mt-2 flex h-6 min-w-fit cursor-pointer list-none rounded-lg bg-[#4F6874] pl-2 pr-2 pt-1 text-center text-xs font-bold text-white"
        onClick={toggleForm}
      >
        + Nouvelle pathologie
      </button>
      <PathologieForm
        selectedPathologie={selectedPathologie}
        formToggle={toggleForm}
        updateData={updateData}
        formDisplay={displayForm}
      />

      {!filterDisplay ? (
        <button
          onClick={toggleFilter}
          className="mr-2 mt-2 flex h-6 min-w-fit cursor-pointer list-none rounded-lg bg-[#4F6874] pl-2 pr-2 pt-1 text-center text-xs font-bold text-white"
        >
          <svg className="mr-2" width="15" height="15" fill="none">
            <rect width="15" height="15" rx="3" fill="#ffffff" />
            <path
              d="M3 9V7H6.5V3.5H8.5V7H12.5V9H8.5V13H6.5V9H3Z"
              fill="#4F6874"
            />
          </svg>
          Critère de recherche
        </button>
      ) : (
        <div className="w-full min-w-fit  rounded-md        bg-white  pb-2 shadow-component  ">
          <button
            onClick={toggleFilter}
            className=" mr-2 mt-2 flex h-6 min-w-fit cursor-pointer list-none rounded-lg bg-[#4F6874] pl-2 pr-2 pt-1 text-center text-xs font-bold text-white"
          >
            <svg className="mr-2" width="15" height="15" viewBox="0 0 15 15">
              <rect width="15" height="15" rx="3" fill="#ffffff" />
              <path d="M3 9V7H12.5V9H3Z" fill="#4F6874" />
            </svg>
            Critère de recherche
          </button>

          <SearchBox
            value={searchQuery}
            onChange={handleSearch}
            label={"Objet du pathologie"}
          />
        </div>
      )}
      <div className="mt-1 flex h-fit w-full min-w-fit flex-col rounded-5px border border-white bg-white shadow-component ">
        <p className="m-2 mt-2 w-[100%] text-xl font-bold text-[#474a52]">
          Liste des pathologies
        </p>
        {/* <p>Nombre de résultats: {totalCount} </p> */}
        {/* <button>Génerer liste des pathologies</button> */}
        <div className="m-2">
          <PathologiesTable
            pathologies={filteredPathologies}
            sortColumn={sortColumn}
            onSort={handleSort}
            onItemSelect={handleSelectPathologie}
            onItemsSelect={handleSelectPathologies}
            selectedItem={selectedPathologie}
            selectedItems={selectedPathologies}
            onEdit={selectedPathologie ? handleEdit : undefined}
            onDelete={
              selectedPathologie !== null || selectedPathologies.length !== 0
                ? handleDelete
                : undefined
            }
          />
          <ReactPaginate
            breakLabel={"..."}
            nextLabel={">"}
            breakClassName={"break-me"}
            pageCount={Math.ceil(totalCount / pageSize)}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            // className="w-max-[92%] mx-3 my-auto flex  w-fit list-none justify-evenly rounded-lg bg-[#D6E1E3] p-3 font-bold text-white"
            previousLabel={"<"}
            renderOnZeroPageCount={null}
            containerClassName={"pagination"}
          />
        </div>
      </div>
    </>
  );
}

export default Pathologies;
